:root {
  --reach-tooltip: 1;
}

[data-reach-tooltip] {
  z-index: 1;
  pointer-events: none;
  white-space: nowrap;
  color: #444;
  background: #f0f0f0;
  border: 1px solid #ccc;
  padding: .25em .5em;
  font-size: 85%;
  position: absolute;
  box-shadow: 2px 2px 10px #0000001a;
}

.device, .device:before, .device:after, .device *, .device :before, .device :after {
  box-sizing: border-box;
  display: block;
}

.device {
  z-index: 1;
  position: relative;
  transform: scale(1);
}

.device .device-frame {
  z-index: 1;
}

.device .device-content {
  object-fit: cover;
  background-color: #fff;
  background-position: center;
  background-size: cover;
  position: relative;
}

.device-iphone-x {
  height: 100%;
  width: 100%;
  aspect-ratio: 1 / 2;
}

.device-iphone-x .device-frame {
  height: 100%;
  width: 100%;
  background: #222;
  border-radius: 68px;
  padding: 28px;
  box-shadow: inset 0 0 2px 2px #c8cacb, inset 0 0 0 7px #e2e3e4;
}

@media screen and (max-width: 1800px) {
  .device-iphone-x .device-frame {
    border-radius: 58px;
    padding: 24px;
  }

  .device-iphone-x .device-content {
    border-radius: 16px;
  }
}

@media screen and (max-width: 1600px) {
  .device-iphone-x .device-frame {
    border-radius: 48px;
    padding: 22px;
  }

  .device-iphone-x .device-content {
    border-radius: 16px;
  }
}

.device-iphone-x .device-content {
  height: 100%;
  width: 100%;
  border-radius: 40px;
}

.device-iphone-x .device-stripe:after, .device-iphone-x .device-stripe:before {
  content: "";
  height: 7px;
  width: 100%;
  z-index: 9;
  border: 0 solid #33333340;
  border-width: 0 7px;
  position: absolute;
  left: 0;
}

.device-iphone-x .device-stripe:after {
  top: 85px;
}

.device-iphone-x .device-stripe:before {
  bottom: 85px;
}

.device-iphone-x .device-header {
  height: 30px;
  width: 47%;
  background: #222;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 27px;
  left: 0%;
  right: 0%;
}

@media screen and (max-width: 1800px) {
  .device-iphone-x .device-header {
    height: 28px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    top: 24px;
  }
}

@media screen and (max-width: 1600px) {
  .device-iphone-x .device-header {
    height: 24px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    top: 20px;
  }
}

.device-iphone-x .device-header:after, .device-iphone-x .device-header:before {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  top: 0;
}

.device-iphone-x .device-header:after {
  background: radial-gradient(circle at 0 100%, #0000 0 75%, #222 75% 100%);
  left: -10px;
}

.device-iphone-x .device-header:before {
  background: radial-gradient(circle at 100% 100%, #0000 0 75%, #222 75% 100%);
  right: -10px;
}

.device-iphone-x .device-sensors:after, .device-iphone-x .device-sensors:before {
  content: "";
  position: absolute;
}

.device-iphone-x .device-sensors:after {
  height: 6px;
  width: 50px;
  background: #444;
  border-radius: 3px;
  margin-left: -25px;
  top: 32px;
  left: 50%;
}

.device-iphone-x .device-sensors:before {
  height: 14px;
  width: 14px;
  background: #444;
  border-radius: 50%;
  margin-left: 40px;
  top: 28px;
  left: 50%;
}

.device-iphone-x .device-btns {
  height: 32px;
  width: 3px;
  background: #c8cacb;
  position: absolute;
  top: 115px;
  left: -3px;
}

.device-iphone-x .device-btns:after, .device-iphone-x .device-btns:before {
  content: "";
  height: 62px;
  width: 3px;
  background: #c8cacb;
  position: absolute;
  left: 0;
}

.device-iphone-x .device-btns:after {
  top: 60px;
}

.device-iphone-x .device-btns:before {
  top: 140px;
}

.device-iphone-x .device-power {
  height: 100px;
  width: 3px;
  background: #c8cacb;
  position: absolute;
  top: 200px;
  right: -3px;
}

.device-macbook-pro {
  height: 100%;
  width: 100%;
}

.device-macbook-pro .device-frame {
  height: 100%;
  width: 94%;
  background: #0d0d0d;
  border-radius: 20px;
  margin: 0 auto;
  padding: 29px 19px 39px;
  position: relative;
  box-shadow: inset 0 0 0 2px #c8cacb;
}

.device-macbook-pro .device-frame:after {
  content: "";
  height: 26px;
  width: 99.6%;
  background: #272626;
  border-radius: 0 0 20px 20px;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.device-macbook-pro .device-frame:before {
  color: #c8cacb;
  content: "GiardoBook Pro";
  opacity: .4;
  height: 16px;
  text-align: center;
  width: 200px;
  z-index: 1;
  margin-left: -100px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

.device-macbook-pro .device-content {
  height: 98%;
  width: 98%;
  border: 2px solid #121212;
  border-radius: 2px;
  margin: auto;
}

.device-macbook-pro .device-power {
  height: 14px;
  width: 100%;
  z-index: 9;
  background: #e2e3e4;
  border: 4px solid #d5d6d8;
  border-width: 2px 4px 0;
  border-radius: 2px 2px 0 0;
  margin-top: -10px;
  position: relative;
}

.device-macbook-pro .device-power:after, .device-macbook-pro .device-power:before {
  content: "";
  position: absolute;
}

.device-macbook-pro .device-power:after {
  height: 10px;
  width: 120px;
  background: #d5d6d8;
  border-radius: 0 0 10px 10px;
  margin-left: -60px;
  top: -2px;
  left: 50%;
  box-shadow: inset 0 0 4px 2px #babdbf;
}

.device-macbook-pro .device-power:before {
  height: 12px;
  width: 100%;
  background: #a0a3a7;
  border-radius: 0 0 180px 180px / 0 0 12px 12px;
  margin: 0 auto;
  top: 10px;
  left: -4px;
  box-shadow: inset 0 -2px 6px #474a4d;
}

.device-macbook-pro.device-spacegray .device-frame {
  box-shadow: inset 0 0 0 2px #767a7d;
}

.device-macbook-pro.device-spacegray .device-power {
  background: #909496;
  border-color: #767a7d;
}

.device-macbook-pro.device-spacegray .device-power:after {
  background: #83878a;
  box-shadow: inset 0 0 4px 2px #6a6d70;
}

.device-macbook-pro.device-spacegray .device-power:before {
  background: #515456;
  box-shadow: inset 0 -2px 6px #000;
}

.device-macbook {
  height: 432px;
  width: 740px;
}

.device-macbook .device-frame {
  height: 428px;
  width: 614px;
  background: #0d0d0d;
  border-radius: 20px;
  margin: 0 auto;
  padding: 29px 19px 39px;
  position: relative;
  box-shadow: inset 0 0 0 2px #c8cacb;
}

.device-macbook .device-frame:after {
  content: "";
  height: 26px;
  width: 610px;
  background: #272626;
  border-radius: 0 0 20px 20px;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.device-macbook .device-frame:before {
  color: #c8cacb;
  content: "GiardoBook";
  height: 16px;
  text-align: center;
  width: 200px;
  z-index: 1;
  margin-left: -100px;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

.device-macbook .device-content {
  height: 360px;
  width: 576px;
  border: 2px solid #121212;
  border-radius: 2px;
}

.device-macbook .device-power {
  height: 4px;
  width: 740px;
  z-index: 9;
  background: #e2e3e4;
  border: 0 solid #d5d6d8;
  border-width: 0 4px;
  border-radius: 2px 2px 0 0;
  margin-top: -10px;
  position: relative;
}

.device-macbook .device-power:after, .device-macbook .device-power:before {
  content: "";
  position: absolute;
}

.device-macbook .device-power:after {
  height: 4px;
  width: 120px;
  background: radial-gradient(circle, #e2e3e4 0 85%, #a0a3a7 100%);
  border: 0 solid #adb0b3;
  border-width: 0 2px;
  margin-left: -60px;
  left: 50%;
}

.device-macbook .device-power:before {
  height: 10px;
  width: 740px;
  background: #a0a3a7;
  border-radius: 0 0 180px 180px / 0 0 10px 10px;
  margin: 0 auto;
  top: 4px;
  left: -4px;
  box-shadow: inset 0 -2px 6px #474a4d;
}

.device-macbook.device-gold .device-frame {
  box-shadow: inset 0 0 0 2px #edccb4;
}

.device-macbook.device-gold .device-power {
  background: #f7e8dd;
  border-color: #edccb4;
}

.device-macbook.device-gold .device-power:after {
  background: radial-gradient(circle, #f7e8dd 0 85%, #dfa276 100%);
  border-color: #e4b08a;
}

.device-macbook.device-gold .device-power:before {
  background: #edccb4;
  box-shadow: inset 0 -2px 6px #83491f;
}

.device-macbook.device-rosegold .device-frame {
  box-shadow: inset 0 0 0 2px #f6a69a;
}

.device-macbook.device-rosegold .device-power {
  background: #facfc9;
  border-color: #f6a69a;
}

.device-macbook.device-rosegold .device-power:after {
  background: radial-gradient(circle, #facfc9 0 85%, #ef6754 100%);
  border-color: #f6a69a;
}

.device-macbook.device-rosegold .device-power:before {
  background: #f6a69a;
  box-shadow: inset 0 -2px 6px #851b0c;
}

.device-macbook.device-spacegray .device-frame {
  box-shadow: inset 0 0 0 2px #767a7d;
}

.device-macbook.device-spacegray .device-power {
  background: #909496;
  border-color: #767a7d;
}

.device-macbook.device-spacegray .device-power:after {
  background: radial-gradient(circle, #909496 0 85%, #515456 100%);
  border-color: #5d6163;
}

.device-macbook.device-spacegray .device-power:before {
  background: #515456;
  box-shadow: inset 0 -2px 6px #000;
}

.device-apple-watch {
  height: 100%;
  aspect-ratio: 200 / 234;
}

.device-apple-watch .device-frame {
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, #0d0d0d, #1f1f1f);
  border-radius: 40px;
  position: relative;
  box-shadow: inset 0 0 2px 2px #adb0b3, inset 0 0 0 6px #e2e3e4, inset 0 0 0 8px #e2e3e4;
}

.device-apple-watch .device-frame:after {
  content: "";
  height: 92%;
  width: 91%;
  border-radius: 30px;
  position: absolute;
  top: 9px;
  left: 9px;
  box-shadow: inset 10px 10px 20px #fff3, inset 0 0 10px #ffffff80, inset 0 0 2px 2px #00000080, 0 0 2px 2px #0006;
}

.device-apple-watch .device-content {
  height: 72%;
  width: 68%;
  border: 2px solid #222;
  border-radius: 2px;
}

.device-apple-watch .device-btns {
  height: 18%;
  width: 8%;
  z-index: 9;
  background: linear-gradient(to top, #b4b5b6, #e2e3e4, #b4b5cf);
  border-left: 2px solid #adb0b3;
  border-radius: 8px 4px 4px 8px / 20px 4px 4px 20px;
  position: absolute;
  top: 20%;
  right: -10px;
  box-shadow: inset 0 0 2px 2px #adb0b3;
}

.device-apple-watch .device-btns:after {
  content: "";
  height: 150%;
  width: 8px;
  background: #e2e3e4;
  border-radius: 4px 2px 2px 4px / 10px 2px 2px 10px;
  position: absolute;
  top: 142%;
  right: 6px;
  box-shadow: inset 0 0 1px 2px #adb0b3;
}

.device-apple-watch .device-btns:before {
  content: "";
  height: 1px;
  width: 6px;
  background: #adb0b3;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  right: 0;
  box-shadow: 0 -16px #adb0b3, 0 -12px #adb0b3, 0 -8px #adb0b3, 0 -4px #adb0b3, 0 4px #adb0b3, 0 8px #adb0b3, 0 12px #adb0b3, 0 16px #adb0b3;
}

@media screen and (max-width: 1800px) {
  .device-apple-watch .device-btns:before {
    box-shadow: 0 -12px #adb0b3, 0 -8px #adb0b3, 0 -4px #adb0b3, 0 4px #adb0b3, 0 8px #adb0b3, 0 12px #adb0b3;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Helvetica Neue, Inter, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.left-64 {
  left: 16rem;
}

.top-4 {
  top: 1rem;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.-left-2 {
  left: -.5rem;
}

.top-20 {
  top: 5rem;
}

.right-24 {
  right: 6rem;
}

.left-0 {
  left: 0;
}

.top-16 {
  top: 4rem;
}

.left-4 {
  left: 1rem;
}

.right-0 {
  right: 0;
}

.top-10 {
  top: 2.5rem;
}

.top-2 {
  top: .5rem;
}

.right-6 {
  right: 1.5rem;
}

.top-12 {
  top: 3rem;
}

.bottom-4 {
  bottom: 1rem;
}

.left-10 {
  left: 2.5rem;
}

.bottom-20 {
  bottom: 5rem;
}

.-left-1 {
  left: -.25rem;
}

.-right-1 {
  right: -.25rem;
}

.right-1 {
  right: .25rem;
}

.right-10 {
  right: 2.5rem;
}

.-top-8 {
  top: -2rem;
}

.top-8 {
  top: 2rem;
}

.left-7 {
  left: 1.75rem;
}

.top-6 {
  top: 1.5rem;
}

.bottom-2 {
  bottom: .5rem;
}

.right-8 {
  right: 2rem;
}

.z-\[100\] {
  z-index: 100;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-\[200\] {
  z-index: 200;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.float-right {
  float: right;
}

.m-0 {
  margin: 0;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-screen {
  height: 100vh;
}

.h-5 {
  height: 1.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-full {
  height: 100%;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-6 {
  height: 1.5rem;
}

.h-3 {
  height: .75rem;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[96\%\] {
  height: 96%;
}

.h-32 {
  height: 8rem;
}

.h-2 {
  height: .5rem;
}

.h-12 {
  height: 3rem;
}

.h-1\/2 {
  height: 50%;
}

.h-\[120px\] {
  height: 120px;
}

.h-20 {
  height: 5rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-1 {
  height: .25rem;
}

.h-auto {
  height: auto;
}

.h-0 {
  height: 0;
}

.h-24 {
  height: 6rem;
}

.max-h-full {
  max-height: 100%;
}

.max-h-\[calc\(100\%_-_180px\)\] {
  max-height: calc(100% - 180px);
}

.min-h-full {
  min-height: 100%;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.w-5 {
  width: 1.25rem;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-3\/12 {
  width: 25%;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-\[34\%\] {
  width: 34%;
}

.w-6 {
  width: 1.5rem;
}

.w-10 {
  width: 2.5rem;
}

.w-4 {
  width: 1rem;
}

.w-12 {
  width: 3rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-2 {
  width: .5rem;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-20 {
  width: 5rem;
}

.w-1\/4 {
  width: 25%;
}

.w-0 {
  width: 0;
}

.w-28 {
  width: 7rem;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/5 {
  width: 40%;
}

.w-56 {
  width: 14rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.-translate-y-8 {
  --tw-translate-y: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2 {
  --tw-translate-y: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-32 {
  --tw-translate-y: -8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-16 {
  --tw-translate-x: 4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-4 {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-8 {
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1 {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-px {
  --tw-translate-x: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-6 {
  --tw-rotate: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.56\] {
  --tw-scale-x: .56;
  --tw-scale-y: .56;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-help {
  cursor: help;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-xl {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.rounded-l-xl {
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-bl-xl {
  border-bottom-left-radius: .75rem;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.rounded-br-\[90\%\] {
  border-bottom-right-radius: 90%;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 224 71 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-t-gray-300 {
  --tw-border-opacity: 1;
  border-top-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-b-gray-400\/20 {
  border-bottom-color: #9ca3af33;
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-t-blue-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.border-t-green-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-blue-100\/75 {
  background-color: #dbeafebf;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-200\/70 {
  background-color: #e5e7ebb3;
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white\/0 {
  background-color: #fff0;
}

.bg-blue-100\/70 {
  background-color: #dbeafeb3;
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-gray-400\/20 {
  background-color: #9ca3af33;
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-white\/40 {
  background-color: #fff6;
}

.bg-red-100\/60 {
  background-color: #fee2e299;
}

.bg-slate-200\/90 {
  background-color: #e2e8f0e6;
}

.bg-slate-200\/70 {
  background-color: #e2e8f0b3;
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-white\/90 {
  background-color: #ffffffe6;
}

.bg-gray-500\/5 {
  background-color: #6b72800d;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-blue-900 {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-to: #1e3a8a00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-pink-900 {
  --tw-gradient-to: #831843;
}

.to-transparent {
  --tw-gradient-to: transparent;
}

.bg-cover {
  background-size: cover;
}

.bg-bottom {
  background-position: bottom;
}

.p-20 {
  padding: 5rem;
}

.p-1 {
  padding: .25rem;
}

.p-8 {
  padding: 2rem;
}

.p-2 {
  padding: .5rem;
}

.p-12 {
  padding: 3rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-px {
  padding: 1px;
}

.p-\[3px\] {
  padding: 3px;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pr-2 {
  padding-right: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-sans {
  font-family: Helvetica Neue, Inter, sans-serif;
}

.font-serif {
  font-family: IBM Plex Serif, serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xxs {
  font-size: .6rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-light {
  font-weight: 300;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-blue-900\/90 {
  color: #1e3a8ae6;
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(220 252 231 / var(--tw-text-opacity));
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.decoration-red-400 {
  text-decoration-color: #f87171;
}

.decoration-wavy {
  text-decoration-style: wavy;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.opacity-20 {
  opacity: .2;
}

.opacity-50 {
  opacity: .5;
}

.opacity-30 {
  opacity: .3;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.opacity-40 {
  opacity: .4;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-60 {
  opacity: .6;
}

.opacity-10 {
  opacity: .1;
}

.opacity-\[0\.06\] {
  opacity: .06;
}

.mix-blend-screen {
  mix-blend-mode: screen;
}

.mix-blend-overlay {
  mix-blend-mode: overlay;
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-lg {
  --tw-blur: blur(16px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

* {
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  background-color: #000;
  overflow: hidden;
}

em, b, strong {
  display: inline !important;
}

._nodrag {
  -webkit-user-drag: none;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
  height: 16px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #edf2f7;
  border-radius: 100vh;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border: 2px solid #edf2f7;
  border-radius: 100vh;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.scrollbar-document::-webkit-scrollbar {
  width: 12px;
  height: 16px;
}

.scrollbar-document::-webkit-scrollbar-track {
  background: #edf2f7;
  border-radius: 4px;
}

.scrollbar-document::-webkit-scrollbar-thumb {
  background: #c0cfe0;
  border: 2px solid #edf2f7;
  border-radius: 4px;
}

.scrollbar-document::-webkit-scrollbar-thumb:hover {
  background: #a0bed0;
}

.hover\:-translate-y-px:hover {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.hover\:bg-blue-100\/100:hover {
  background-color: #dbeafe;
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:bg-white\/20:hover {
  background-color: #fff3;
}

.hover\:bg-blue-100\/90:hover {
  background-color: #dbeafee6;
}

.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.hover\:bg-red-200\/60:hover {
  background-color: #fecaca99;
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.hover\:bg-orange-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-60:hover {
  --tw-bg-opacity: .6;
}

.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:duration-75:hover {
  transition-duration: 75ms;
}

.hover\:duration-100:hover {
  transition-duration: .1s;
}

.focus\:outline-blue-100:focus {
  outline-color: #dbeafe;
}

.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:text-blue-400:active {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.active\:duration-\[10ms\]:active {
  transition-duration: 10ms;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:bg-opacity-100:disabled {
  --tw-bg-opacity: 1;
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:inline-block {
  display: inline-block;
}

@media (min-width: 768px) {
  .md\:left-40 {
    left: 10rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:w-32 {
    width: 8rem;
  }
}

@media (min-width: 1024px) {
  .lg\:left-48 {
    left: 12rem;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:h-40 {
    height: 10rem;
  }

  .lg\:w-6 {
    width: 1.5rem;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-5\/6 {
    width: 83.3333%;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:-translate-y-20 {
    --tw-translate-y: -5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-1 {
    padding: .25rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 1280px) {
  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:mt-3 {
    margin-top: .75rem;
  }

  .xl\:mt-2 {
    margin-top: .5rem;
  }

  .xl\:h-7 {
    height: 1.75rem;
  }

  .xl\:h-8 {
    height: 2rem;
  }

  .xl\:h-3 {
    height: .75rem;
  }

  .xl\:h-24 {
    height: 6rem;
  }

  .xl\:h-20 {
    height: 5rem;
  }

  .xl\:h-48 {
    height: 12rem;
  }

  .xl\:h-6 {
    height: 1.5rem;
  }

  .xl\:w-7 {
    width: 1.75rem;
  }

  .xl\:w-20 {
    width: 5rem;
  }

  .xl\:w-3 {
    width: .75rem;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:w-24 {
    width: 6rem;
  }

  .xl\:w-4\/5 {
    width: 80%;
  }

  .xl\:w-14 {
    width: 3.5rem;
  }

  .xl\:w-40 {
    width: 10rem;
  }

  .xl\:w-6 {
    width: 1.5rem;
  }

  .xl\:-translate-y-32 {
    --tw-translate-y: -8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-8 {
    --tw-translate-x: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:-translate-y-8 {
    --tw-translate-y: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-x-1 {
    --tw-translate-x: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:translate-y-2 {
    --tw-translate-y: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:gap-24 {
    gap: 6rem;
  }

  .xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .xl\:rounded-xl {
    border-radius: .75rem;
  }

  .xl\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .xl\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .xl\:p-3 {
    padding: .75rem;
  }

  .xl\:p-16 {
    padding: 4rem;
  }

  .xl\:p-10 {
    padding: 2.5rem;
  }

  .xl\:p-1 {
    padding: .25rem;
  }

  .xl\:p-2 {
    padding: .5rem;
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xl\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:left-64 {
    left: 16rem;
  }

  .\32 xl\:left-1 {
    left: .25rem;
  }

  .\32 xl\:top-28 {
    top: 7rem;
  }

  .\32 xl\:bottom-6 {
    bottom: 1.5rem;
  }

  .\32 xl\:bottom-3 {
    bottom: .75rem;
  }

  .\32 xl\:right-8 {
    right: 2rem;
  }

  .\32 xl\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .\32 xl\:mt-4 {
    margin-top: 1rem;
  }

  .\32 xl\:mb-2 {
    margin-bottom: .5rem;
  }

  .\32 xl\:mt-2 {
    margin-top: .5rem;
  }

  .\32 xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .\32 xl\:h-8 {
    height: 2rem;
  }

  .\32 xl\:h-10 {
    height: 2.5rem;
  }

  .\32 xl\:h-4 {
    height: 1rem;
  }

  .\32 xl\:h-7 {
    height: 1.75rem;
  }

  .\32 xl\:h-28 {
    height: 7rem;
  }

  .\32 xl\:h-\[48px\] {
    height: 48px;
  }

  .\32 xl\:h-52 {
    height: 13rem;
  }

  .\32 xl\:w-8 {
    width: 2rem;
  }

  .\32 xl\:w-24 {
    width: 6rem;
  }

  .\32 xl\:w-4 {
    width: 1rem;
  }

  .\32 xl\:w-7 {
    width: 1.75rem;
  }

  .\32 xl\:w-2\/3 {
    width: 66.6667%;
  }

  .\32 xl\:w-28 {
    width: 7rem;
  }

  .\32 xl\:w-3\/4 {
    width: 75%;
  }

  .\32 xl\:w-\[48px\] {
    width: 48px;
  }

  .\32 xl\:w-44 {
    width: 11rem;
  }

  .\32 xl\:-translate-y-16 {
    --tw-translate-y: -4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\32 xl\:translate-x-4 {
    --tw-translate-x: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\32 xl\:translate-y-4 {
    --tw-translate-y: 1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\32 xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
  }

  .\32 xl\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .\32 xl\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .\32 xl\:border-4 {
    border-width: 4px;
  }

  .\32 xl\:p-4 {
    padding: 1rem;
  }

  .\32 xl\:p-10 {
    padding: 2.5rem;
  }

  .\32 xl\:p-16 {
    padding: 4rem;
  }

  .\32 xl\:p-12 {
    padding: 3rem;
  }

  .\32 xl\:p-6 {
    padding: 1.5rem;
  }

  .\32 xl\:p-8 {
    padding: 2rem;
  }

  .\32 xl\:p-2 {
    padding: .5rem;
  }

  .\32 xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .\32 xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .\32 xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .\32 xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .\32 xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .\32 xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .\32 xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .\32 xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .\32 xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .\32 xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .\32 xl\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .\32 xl\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 1900px) {
  .\33 xl\:-top-8 {
    top: -2rem;
  }

  .\33 xl\:bottom-8 {
    bottom: 2rem;
  }

  .\33 xl\:bottom-4 {
    bottom: 1rem;
  }

  .\33 xl\:right-10 {
    right: 2.5rem;
  }

  .\33 xl\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .\33 xl\:mt-8 {
    margin-top: 2rem;
  }

  .\33 xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .\33 xl\:mt-3 {
    margin-top: .75rem;
  }

  .\33 xl\:h-48 {
    height: 12rem;
  }

  .\33 xl\:h-8 {
    height: 2rem;
  }

  .\33 xl\:h-32 {
    height: 8rem;
  }

  .\33 xl\:h-4 {
    height: 1rem;
  }

  .\33 xl\:h-60 {
    height: 15rem;
  }

  .\33 xl\:w-8 {
    width: 2rem;
  }

  .\33 xl\:w-32 {
    width: 8rem;
  }

  .\33 xl\:w-2\/3 {
    width: 66.6667%;
  }

  .\33 xl\:w-48 {
    width: 12rem;
  }

  .\33 xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .\33 xl\:p-12 {
    padding: 3rem;
  }

  .\33 xl\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .\33 xl\:pl-3 {
    padding-left: .75rem;
  }

  .\33 xl\:pr-2 {
    padding-right: .5rem;
  }

  .\33 xl\:pl-4 {
    padding-left: 1rem;
  }

  .\33 xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .\33 xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .\33 xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .\33 xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .\33 xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .\33 xl\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .\33 xl\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .\33 xl\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 2400px) {
  .\34 xl\:h-\[140px\] {
    height: 140px;
  }

  .\34 xl\:w-32 {
    width: 8rem;
  }

  .\34 xl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .\34 xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .\34 xl\:leading-tight {
    line-height: 1.25;
  }
}

/*# sourceMappingURL=index.ea9194c4.css.map */
