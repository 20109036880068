@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  overflow: hidden;
  background-color: black;
}

em,
b,
strong {
  display: inline !important;
}

._nodrag {
  -webkit-user-drag: none;
}

/* width */
.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
  height: 16px;
}

/* Track */
.scrollbar-thin::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 2px solid #edf2f7;
}

/* Handle on hover */
.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

/* width */
.scrollbar-document::-webkit-scrollbar {
  width: 12px;
  height: 16px;
}

/* Track */
.scrollbar-document::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #edf2f7;
}

/* Handle */
.scrollbar-document::-webkit-scrollbar-thumb {
  background: #c0cfe0;
  border-radius: 4px;
  border: 2px solid #edf2f7;
}

/* Handle on hover */
.scrollbar-document::-webkit-scrollbar-thumb:hover {
  background: #a0bed0;
}
